import React, { useEffect, useState, dangerouslySetInnerHTML } from "react";
import axios from "axios";
import {
  Box,
  // Select,
  // MenuItem,
  Button,
  IconButton,
  // IconButton,
  TextField,
  // Grid,
  // InputBase,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { appConfig } from "../../config";
import ConfirmButtons from "../widgets/ConfirmButtons.js";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloseIcon from "@mui/icons-material/Close";
import pretty from "pretty";
import { Edit } from "@mui/icons-material";

const HeroForm = () => {
  const theme = useTheme();
  const [title, setTitle] = useState("");
  const [label, setLabel] = useState("");
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState("");

  const [heros, setHeros] = useState(null);

  const fetchHeros = async () => {
    try {
      const response = await axios.get(`${appConfig.NODEURL}api/hero`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });

      setHeros(response.data);
    } catch (error) {
      console.error("Error fetching users", error);
    }
  };

  useEffect(() => {
    fetchHeros();
    return () => {};
  }, []);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("label", label);
    formData.append("description", description);
    formData.append("image", image);

    try {
      await axios.post(`${appConfig.NODEURL}api/hero`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
          "Content-Type": "multipart/form-data",
        },
      });
      //alert("Hero content saved successfully!");
      fetchHeros();
    } catch (error) {
      console.error("Error saving hero content:", error);
    }
  };

  const handleDeleteHero = async (id) => {
    try {
      await axios.delete(`${appConfig.NODEURL}api/hero/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
          "Content-Type": "application/json", // Add content-type if sending JSON
        },
      });
      console.log("Image deleted successfully!");
      // Refresh page list (simplest way)
      // window.location.reload();
      fetchHeros();
    } catch (error) {
      console.error("Error deleting page", error);
    }
  };

  const EditHero = ({ fetchHeros, hero }) => {
    const [editShow, setEditShow] = useState(false);

    const [_hero, setHero] = useState(hero);

    const handleUpdate = (e) => {
      // console.log(e.target.name);
      const newHero = { ..._hero };
      newHero[e.target.name] = e.target.value;
      // console.log(newHero);
      setHero(newHero);
    };

    const handleSubmitHeroUpdate = async () => {
      setEditShow(false);
      try {
        const res = await axios.put(
          `${appConfig.NODEURL}api/hero/${_hero.id}`,
          _hero,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
              "Content-Type": "application/json", // Add content-type if sending JSON
            },
          }
        );
        fetchHeros();
      } catch (err) {}
    };

    useEffect(() => {
      setHero(hero);
    }, [hero]);

    if (editShow) {
      return (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: 300,
            height: 300,
            backgroundColor: "#ffffff",
            zIndex: 5000, //theme.zIndex.tooltip,
            border: "1px solid black",
            borderRadius: 10,
          }}
        >
          <form onSubmit={handleSubmitHeroUpdate}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <TextField
                label="label"
                style={{ width: 250 }}
                name="label"
                size="small"
                onChange={handleUpdate}
                value={_hero.label}
              ></TextField>
              <TextField
                label="title (optional)"
                name="title"
                size="small"
                onChange={handleUpdate}
                value={_hero.title}
              ></TextField>
              <TextField
                label="description (optional)"
                multiline
                name="description"
                onChange={handleUpdate}
                value={_hero.description}
              ></TextField>
              <IconButton
                style={{ position: "absolute", top: 0, right: 0 }}
                onClick={() => setEditShow(false)}
              >
                <CloseIcon />
              </IconButton>
              <button type="submit">Update</button>
            </div>
          </form>
        </div>
      );
    }

    return (
      <button
        onClick={() => {
          setEditShow(true);
        }}
      >
        {hero.label}
      </button>
    );
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            border: "1px solid black",
            width: "fit-content",
            borderRadius: 5,
            padding: 5,
            alignItems: "center",
          }}
        >
          <div>
            <input
              placeholder="Label"
              type="text"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              required
            />
          </div>
          <div>
            <input
              placeholder="Title (optional)"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          <div>
            <textarea
              placeholder="Description (optional)"
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <div>
            <label>Background Image</label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              required
            />
          </div>

          <Button sx={{ ...theme.typography.button }} type="submit">
            Add New
          </Button>
        </div>
      </form>

      <textarea style={{ display: "none", whitespace: "wrap" }}>
        {heros &&
          JSON.stringify(
            heros.map((hero) => pretty(hero.imgmarkup)),
            null,
            3
          )}
      </textarea>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "3vw",
          alignItems: "flex-start",
          justifyContent: "space-around",
          marginTop: 2,
          marginLeft: 2,
          border: 1,
          //   margin: "2vw",
        }}
      >
        {heros &&
          heros.map((hero, index) => {
            if (index > 1) {
              // return;
            }
            console.log(hero);
            return (
              <div key={index} style={{ maxWidth: 300, maxHeight: 300 }}>
                <div
                  style={{
                    width: "100%",
                    // display: "flex",
                    position: "relative",
                  }}
                >
                  <ConfirmButtons
                    label="delete"
                    action={handleDeleteHero}
                    args={hero.id}
                    icon={<DeleteForeverIcon />}
                  />
                  <EditHero fetchHeros={fetchHeros} hero={hero} />
                  <div style={{ ...theme.typography.h5 }}>{hero.title}</div>
                </div>

                <div
                  style={{ width: 100 }}
                  key={index}
                  dangerouslySetInnerHTML={{ __html: hero.imgmarkup }}
                />
                <div style={{ width: "100%" }}>{hero.description}</div>
              </div>
            );
          })}
      </Box>
    </div>
  );
};

export default HeroForm;
