import { configureStore, createSlice } from "@reduxjs/toolkit";

// Create a slice for the counter
const counterSlice = createSlice({
  name: "counter", // A name for the slice
  initialState: { count: 0 }, // Initial state
  reducers: {
    increment: (state) => {
      state.count += 1; // Toolkit allows direct state mutation with Immer
    },
    decrement: (state) => {
      state.count -= 1;
    },
    reset: (state) => {
      state.count = 0;
    },
  },
});

const rteContent = createSlice({
  name: "rte",
  initialState: { html: "", json: {} }, // Initial state
  reducers: {
    update: (state, action) => {
      state.json = {};
      state.html = action.payload.html;
      state.json = action.payload.json;
      state.label = action.payload.label;
      state.title = action.payload.title;
    },
  },
});

// Export actions generated by createSlice
export const { increment, decrement, reset } = counterSlice.actions;

export const { update } = rteContent.actions;

// Create the store
const store = configureStore({
  reducer: {
    counter: counterSlice.reducer,
    rte: rteContent.reducer,
  },
});

export default store;
