// CalendarList.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EditEvent from "../components/Events/EditEvent.js";
import AddEvent from "../components/Events/AddEvent.js";

import Header from "../components/Header/index.js";
import ValidateJwt from "../components/Login/ValidateJwt.js";

import isUserValidated from "../util/isUserValidated.js";

import { appConfig } from "../config.js";

function ManageEvents({ trig }) {
  const [events, setEvents] = useState([]);
  const [fields, setFields] = useState([]);

  const [eventId, setEventId] = useState(null);
  const onEdit = (id) => {
    setEventId(id);
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (!isUserValidated()) {
      navigate("/login", {
        state: {
          returnTo: window.location.pathname + "/" + window.location.search,
          // cartItems: items ? items : [_fields],
        },
      });
    }
  }, [trig]);

  const fetchEvents = () => {
    fetch(`${appConfig.NODEURL}api/events`)
      .then((res) => res.json())
      .then((data) => setEvents(data.rows))
      .then((data) => setFields(data.fields))
      .then((data) => console.log(data.fields))
      .catch((err) => console.error("Error fetching events:", err));
  };
  useEffect(() => {
    fetchEvents();
  }, []);

  const handleDelete = (id) => {
    fetch(`/api/events/${id}`, { method: "DELETE" })
      .then(() => setEvents(events.filter((event) => event.id !== id)))
      .catch((err) => console.error("Error deleting event:", err));
  };

  return (
    <Header>
      <ValidateJwt />
      <AddEvent fields={events.fields} />
      {eventId && (
        <EditEvent
          fields={fields}
          eventId={eventId}
          onClear={() => setEventId(null)}
        />
      )}

      {events &&
        events.map((event, index) => (
          <li key={index}>
            <strong>{event.name}</strong> –{" "}
            {new Date(event.start_time).toLocaleString()}
            <button onClick={() => onEdit(event.id)}>Edit</button>
            <button onClick={() => handleDelete(event.id)}>Delete</button>
          </li>
        ))}
    </Header>
  );
}

export default ManageEvents;
